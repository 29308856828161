.ContainerPageLogin {
    margin: 0 auto;
}

.formContainerLogin {
    margin: 0 auto !important;
    padding: 2% !important;
    width: 420px !important;
}

.PageTitleLogin {
    text-align: center !important;
    margin: 0 auto !important;
    padding: 2% 0 !important;
}

form.formContainerLogin button {
    width: 100% !important;
    margin: 2% auto !important;
}

input.mdc-text-field__input {
    padding: 10px !important;
}

